import { useEffect, useState } from 'react';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import useReactNativeEvents from '../../../../hooks/useReactNativeEvents';
import {
  sendPurpleIdToMakeKakaoPay,
  sendPurpleIdToMakePaypal,
  sendPurpleIdToMakeTossPay,
} from '../../../../utils/request';

export const usePurpleCharge = (
  data: any,
  selectedMethod: 'Paypal' | 'TossPay' | 'KakaoPay',
  currentUser: any,
  currentImageIndex: number,
  setCurrentImageIndex: React.Dispatch<React.SetStateAction<number>>,
  onPaymentSuccess: () => Promise<void>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { postMessageToRN } = useReactNativeEvents();
  const platform = localStorage.getItem('platform');
  const isWeb = platform !== 'ios' && platform !== 'android';
  const platformType = isWeb ? 'web' : 'app';

  const handleCharge = async () => {
    if (currentImageIndex === 0) setCurrentImageIndex(2);
    else if (currentImageIndex === 1) {
      trackEvent(eventNames.pointPaymentClick, currentUser, {
        point: data.points,
        bonusPoint: `${data.bonusPoints}%`,
        price: isWeb ? data.priceInWeb : data.price,

        currency: 'KRW',
        paymentMethod: isWeb ? selectedMethod : 'InAppPurchase',
        platformType,
      });

      setIsLoading(true);
      if (platform === 'ios' || platform === 'android') {
        postMessageToRN('onPurchaseButtonClicked', {
          productId: data?.productId ?? '',
        });
      } else {
        try {
          if (selectedMethod === 'TossPay') {
            const response = await sendPurpleIdToMakeTossPay(data.productId);
            if (response?.data.tossResponse.checkoutPage) {
              const checkoutPage = response.data.tossResponse.checkoutPage;
              window.open(checkoutPage, '_blank');
            }
          } else if (selectedMethod === 'KakaoPay') {
            const response = await sendPurpleIdToMakeKakaoPay(data.productId);
            if (response?.data.kakaoResponse.next_redirect_pc_url) {
              const checkoutPage = response.data.kakaoResponse.next_redirect_pc_url;
              window.open(checkoutPage, '_blank');
            }
          } else if (selectedMethod === 'Paypal') {
            const response = await sendPurpleIdToMakePaypal(data.productId);
            if (response?.data.paypalResponse.links[1].href) {
              const checkoutPage = response.data.paypalResponse.links[1].href;
              window.open(checkoutPage, '_blank');
            }
          }
        } catch (error) {
          console.error(
            'An error occurred while sending Purple ID to Payment service:',
            error
          );
        }
      }
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      let data;
      if (event?.data) {
        if (
          event?.data === 'Kakao payment is completed!' ||
          event?.data === 'Paypal payment is completed!'
        ) {
          setIsLoading(false);
          onPaymentSuccess();
        } else {
          try {
            data = JSON.parse(event?.data);
          } catch {
            data = event.data;
          }

          if (
            data?.type === 'callback' &&
            ['USER_CANCELLED', 'PURCHASE_COMPLETED', 'PURCHASE_ERROR'].includes(
              data.response
            )
          ) {
            setIsLoading(false);
            if (data.response === 'PURCHASE_COMPLETED') onPaymentSuccess();
          }
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [onPaymentSuccess]);
  return { isLoading, handleCharge };
};
