import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ChannelTalkLayout from './components/Layout/ChannelTalkLayout';
import WebStoreLayout from './components/Layout/WebStoreLayout';
import StoreTopBar from './components/store/Bar/StoreTopBar';
import { PATH } from './constants/_common/path';
import ProtectedRoute from './router/ProtectedRoute';
import PublicOnlyRoute from './router/PublicOnlyRoute';
import ComingSoonPage from './screens/ComingSoonPage/ComingSoonPage.js';
import AccessDenied from './screens/accessDenied';
import SearchScreen from './screens/search/SearchScreen/SearchScreen';
import BrandDetail from './screens/store/BrandPage/BrandDetail';
import BrandPage from './screens/store/BrandPage/index.js';
import ExhibitionPage from './screens/store/ExhibitionPage/ExhibitionPage';
import FavoritePage from './screens/store/FavoritePage';
import LoginPage from './screens/store/LoginPage/LoginPage';
import MyPage from './screens/store/MyPage/MyPage';
import PurchaseHistory from './screens/store/PurchaseHistory';
import PurplePage from './screens/store/PurplePage';
import KakaoComplete from './screens/store/PurplePage/KakaoComplete';
import PaypalComplete from './screens/store/PurplePage/PaypalComplete';
import TossComplete from './screens/store/PurplePage/TossComplete';
import ReviewSubmitPage from './screens/store/ReviewSubmitPage';
import SignUpPage from './screens/store/SignUpPage/SignUpPage';
import StickerDetail from './screens/store/StickerDetail';
import StickerPage from './screens/store/StickerPage';
import TemplateDetail from './screens/store/TemplateDetail';
import TemplatePage from './screens/store/TemplatePage';
import Today from './screens/store/Today/index.js';
import { getHaplStoreData } from './utils/request';
import MaintenancePage from './screens/MaintenancePage';

function MainComponent() {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('accessToken');
  const refreshToken = urlParams.get('refreshToken');
  const userId = urlParams.get('userId');
  const platform = urlParams.get('platform');
  if (accessToken) localStorage.setItem('accessToken', accessToken);
  if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
  if (userId) localStorage.setItem('userId', userId);
  if (platform) localStorage.setItem('platform', platform);

  const [apiCalling, setApiCalling] = useState(false);
  const [data1, setData1] = useState({ data: null, isPending: false });
  const [data2, setData2] = useState({});
  const [data3, setData3] = useState({});
  const [data4, setData4] = useState({});


  const location = useLocation();
  const isLoggedIn = !!localStorage.getItem('userId');

  useEffect(() => {
    let segments = location.pathname.split('/');
    segments = segments.filter((s) => s);
    const shouldHide =
      location.pathname.includes('brand/detail') ||
      location.pathname.includes('sticker/detail') ||
      location.pathname.includes('sticker/detail') ||
      location.pathname.includes('store/purple') ||
      location.pathname.includes('store/favorite');

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'routeChanged',
        data: {
          disableScreenshot:
            location.pathname.includes('sticker/detail') ||
            location.pathname.includes('search') ||
            false,
          hideBottomTabs: segments?.length >= 4 || shouldHide,
        },
      })
    );
  }, [location]);

  const callTabsApis = async () => {
    if (apiCalling) return;
    setData1({ ...data1, isPending: true });

    getHaplStoreData('today')
      .then((response) => {
        const today = response.data?.data;
        setData1({ data: today, isPending: false });
      })
      .catch((error) => {
        setApiCalling(false);
      });

    getHaplStoreData('sticker')
      .then((response) => {
        const stickers = response.data?.data;
        setData2(stickers);
      })
      .catch((error) => {
        setApiCalling(false);
        // Handle any errors here
        console.error('Error:', error);
      });

    getHaplStoreData('template')
      .then((response) => {
        const templates = response.data?.data;
        setData3(templates);
      })
      .catch((error) => {
        setApiCalling(false);
        // Handle any errors here
        console.error('Error:', error);
      });
  };

  const fetchBrands = async () => {
    const response = await getHaplStoreData('brand');
    if (response?.data?.status) setData4(response?.data?.data);
  };

  useEffect(() => {
    fetchBrands();
    setApiCalling(true);
    callTabsApis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='App'>
      <Routes>
        <Route path={PATH.DEFAULT} element={<Navigate to={PATH.STORE} replace />} />

        <Route element={<WebStoreLayout />}>
          <Route path={PATH.SEARCH} element={<SearchScreen />} />

          <Route element={<ChannelTalkLayout />}>
            <Route element={<StoreTopBar />}>
              <Route
                path={PATH.STORE}
                element={<Today data={data1.data} isPending={data1.isPending} />}
              />
              <Route
                path={`${PATH.STORE}/sticker`}
                element={<StickerPage data={data2} />}
              />
              <Route
                path={`${PATH.STORE}/template`}
                element={<TemplatePage data={data3} />}
              />
              <Route path={`${PATH.STORE}/brand`} element={<BrandPage data={data4} />} />
            </Route>
            <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
              <Route path={PATH.MYPAGE} element={<MyPage />} />
            </Route>
            <Route element={<PublicOnlyRoute isLoggedIn={isLoggedIn} />}>
              <Route path={PATH.LOGIN} element={<LoginPage />} />
            </Route>
          </Route>
        </Route>

        <Route element={<PublicOnlyRoute isLoggedIn={isLoggedIn} />}>
          <Route path={PATH.SIGN_UP} element={<SignUpPage />} />
        </Route>

        <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
          <Route path={`${PATH.STORE}/purple`} element={<PurplePage />} />
          <Route path={`${PATH.STORE}/favorite`} element={<FavoritePage />} />
          <Route
            path={`${PATH.STORE}/payment-complete/toss`}
            element={<TossComplete />}
          />
          <Route
            path={`${PATH.STORE}/payment-complete/kakao`}
            element={<KakaoComplete />}
          />
          <Route
            path={`${PATH.STORE}/payment-complete/paypal`}
            element={<PaypalComplete />}
          />
          <Route path={PATH.PURCHASE_HISTORY} element={<PurchaseHistory />} />

          <Route
            path={`${PATH.CREATE_REVIEW}/:productId`}
            element={<ReviewSubmitPage mode='create' />}
          />
          <Route
            path={`${PATH.EDIT_REVIEW}/:productId/:reviewId`}
            element={<ReviewSubmitPage mode='edit' />}
          />
        </Route>

        <Route path={`${PATH.STORE}/exhibition`} element={<ExhibitionPage />} />
        <Route path={`${PATH.STICKER_DETAIL}/:id`} element={<StickerDetail />} />
        <Route path={`${PATH.TEMPLATE_DETAIL}/:id`} element={<TemplateDetail />} />
        <Route path={`${PATH.BRAND_DETAIL}/:id`} element={<BrandDetail />} />

        <Route path={PATH.COMING_SOON} element={<ComingSoonPage />} />
        <Route path={PATH.MAINTENANCE_PAGE} element={<MaintenancePage />} />
        <Route path='*' element={<AccessDenied />} />
      </Routes>
    </div>
  );
}

export default MainComponent;
