import axios from 'axios';
import apiClient from 'src/apis/apiClient';
import { API_URL } from './configs';
import { getQueryString } from './getQueryString';

const TOKEN_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  USER_ID: 'userId',
} as const;

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(TOKEN_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(TOKEN_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(TOKEN_STORAGE_KEYS.ACCESS_TOKEN);
  return accessToken || '';
};

export const getRefreshToken = () => {
  const accessToken = localStorage.getItem(TOKEN_STORAGE_KEYS.REFRESH_TOKEN);
  return accessToken || '';
};

const getUserId = () => {
  const userId = localStorage.getItem(TOKEN_STORAGE_KEYS.USER_ID);
  return userId || '';
};

export const setUserInfoInLocalStorage = ({
  accessToken,
  refreshToken,
  userId,
}: {
  accessToken: string;
  refreshToken: string;
  userId: string;
}) => {
  localStorage.setItem(TOKEN_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
  localStorage.setItem(TOKEN_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  localStorage.setItem(TOKEN_STORAGE_KEYS.USER_ID, userId);
};

export const removeUserInfoLocalStorage = () => {
  Object.values(TOKEN_STORAGE_KEYS).forEach((key) => localStorage.removeItem(key));
};

export const getAppConfigs = async () => {
  try {
    const url = `${API_URL}/api/configs`;
    const response = await apiClient.get(url);

    return response;
  } catch (e) {
    throw e;
  }
};

export const getHaplStoreData = async (tab) => {
  try {
    const url = `${API_URL}/api/store/home/v2?tab=${tab}&userId=${getUserId()}`;
    return await apiClient.get(url);
  } catch (e) {
    throw e;
  }
};

export const getBrandDetails = async (id) => {
  try {
    const url = `${API_URL}/api/brands/details/${id}`;
    const response = await apiClient.get(url);

    return response;
  } catch (e) {
    throw e;
  }
};

export const getProductDetails = async (productId) => {
  try {
    const url = `${API_URL}/api/products/details/${productId}?userId=${getUserId()}`;
    return await apiClient.get(url);
  } catch (e) {
    throw e;
  }
};

// Wish List

export const addToWishlist = async (
  productId: string | null = null,
  brandId: string | null = null
) => {
  try {
    return await apiClient.post(`${API_URL}/api/wishlist`, { productId, brandId });
  } catch (e) {
    throw e;
  }
};

export const removeFromWishlist = async (id) => {
  try {
    return await apiClient.delete(`${API_URL}/api/wishlist/${id}`);
  } catch (e) {
    throw e;
  }
};

export const getProductsYouMayLike = async (categories) => {
  try {
    const url = `${API_URL}/api/products/get-by-categories`;
    const response = await apiClient.post(url, { categories });

    return response;
  } catch (e) {
    throw e;
  }
};

export const getWishlistByType = async (type = 'sticker') => {
  try {
    return await apiClient.get(`${API_URL}/api/wishlist?type=${type}`);
  } catch (e) {
    throw e;
  }
};

export const getProductByBrandId = async (brandId: string, type?: string) => {
  try {
    const url = `${API_URL}/api/products/get-by-brand/${brandId}${type ? `?type=${type}` : ''}`;
    const response = await apiClient.get(url);

    return response;
  } catch (e) {
    throw e;
  }
};

export const purchaseProduct = async (productId) => {
  try {
    return await apiClient.post(`${API_URL}/api/products/purchase/${productId}`);
  } catch (e) {
    throw e;
  }
};

// Purple points

export const sendPurpleIdToMakeTossPay = async (productId: string) => {
  try {
    return await apiClient.post(`${API_URL}/api/payments/purchase-points/toss`, {
      productId,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getTossPaySuccess = async () => {
  try {
    return await apiClient.get(`${API_URL}/api/payments/purchase-points/toss`);
  } catch (e) {
    console.log(e);
  }
};

export const sendPurpleIdToMakeKakaoPay = async (productId: string) => {
  try {
    return await apiClient.post(`${API_URL}/api/payments/purchase-points/kakao-pay`, {
      productId,
    });
  } catch (e) {
    console.log(e);
  }
};

export const approveKakaoPay = async (
  pg_token: string,
  orderNum: number,
  productId: string
) => {
  try {
    return await apiClient.post(
      `${API_URL}/api/payments/purchase-points/kakao-pay/approve`,
      {
        pg_token,
        orderNum,
        productId,
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const sendPurpleIdToMakePaypal = async (productId: string) => {
  try {
    return await axios.post(
      `${API_URL}/api/payments/purchase-points/paypal`,
      {
        productId,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const approvePaypal = async (token: string) => {
  try {
    return await axios.post(
      `${API_URL}/api/payments/purchase-points/paypal/approve?paypal_order_id=${token}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const getPurplePointsList = async () => {
  try {
    return await apiClient.get<{
      status: boolean;
      data: {
        _id: string;
        points: number;
        bonusPoints: number;
        productId: string;
        inAppPurchaseId: string;
        createdAt: string;
        updatedAt: string;
        __v: number;
        state: 'APPROVED';
        price: number;
        priceInWeb: number;
        id: string;
      }[];
    }>(`${API_URL}/api/purple-points`);
  } catch (e) {
    throw e;
  }
};

export const getPurpleHistory = async () => {
  try {
    return await apiClient.get(`${API_URL}/api/user-points-history`);
  } catch (e) {
    throw e;
  }
};

export const getUserBalance = async () => {
  try {
    return await apiClient.get(`${API_URL}/api/purple-points/get-user-balance`);
  } catch (e) {
    console.log(e);
  }
};

export const me = async () => {
  try {
    return await apiClient.get(`${API_URL}/api/users/me`);
  } catch (e) {
    console.log(e);
  }
};

export const getTemplateOrFolderId = async (productId) => {
  try {
    return await apiClient.get(
      `${API_URL}/api/products/${productId}/template-or-folder-id/get`
    );
  } catch (e) {
    console.log(e);
  }
};

// Mixpanel
export const sendMixpanelEvent = async (
  event: string,
  userInfo: { name: string; email: string },
  options?: any
) => {
  try {
    await apiClient.post(`${API_URL}/api/mixpanel/webhook`, {
      event,
      userInfo,
      options: options || {},
    });
  } catch (e) {
    console.log(e);
  }
};

// Search
export type FetchSearchResultParams = {
  keyword: string;
  type: '' | 'sticker' | 'fragmentSticker' | 'template' | 'brand';
  limit?: number;
};

export const fetchSearchResult = async (queryParams: FetchSearchResultParams) => {
  const url = `${API_URL}/api/search${getQueryString(queryParams)}`;

  return apiClient.get(url);
};

export const saveWatchdog = async (logs) => {
  try {
    return await apiClient.post(`${API_URL}/api/watchdog`, {
      logs,
    });
  } catch (e) {
    console.log(e);
  }
};
