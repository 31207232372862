import { useTranslation } from 'react-i18next';
import { SalesData } from 'src/apis/types/products.types';
import { formateDateTime } from 'src/utils/dateTime';
import * as S from './ProductInfo.styles';

type Props = {
  salesData: SalesData;
};

const ProductInfo = ({ salesData }: Props) => {
  const { engName, korName, purchasePrice, soldAt, thumbnailSignUrl, salesType } =
    salesData;

  const { t, i18n } = useTranslation();
  const productNameObj = {
    ko: korName,
    en: engName,
  };

  return (
    <S.Container>
      <S.ProductImage src={thumbnailSignUrl} $isSticker={salesType === 'sticker'} />
      <S.ProductInfo>
        <S.ProductName>{productNameObj[i18n.language]}</S.ProductName>
        <S.ProductPrice>{`${purchasePrice.toLocaleString()}P`}</S.ProductPrice>
        <S.ProductPurchaseDate>
          {t('store.submitReview.purchased', {
            purchasedDate: formateDateTime(soldAt, 'YY.MM.DD'),
          })}
        </S.ProductPurchaseDate>
      </S.ProductInfo>
    </S.Container>
  );
};

export default ProductInfo;
