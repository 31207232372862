import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postReviewLike } from 'src/apis/products';
import { ReactComponent as LikeImageFilled } from 'src/assets/images/store/favorite_filled.svg';
import { ReactComponent as LikeImageUnfilled } from 'src/assets/images/store/favorite_outlined.svg';
import useModalManager from 'src/hooks/modal/useModalManager';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import userQueryOptions from 'src/queries/userQueryOptions';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import * as S from './ReviewLike.styles';

type Props = {
  productId: string;
  reviewId: string;
  likeCount: number;
  hasLiked: boolean;
  reviewAuthorId: string;
  nickName: string;
  productName: string;
  productType: string;
  brandName: string;
};

const ReviewLike = ({
  likeCount,
  productId,
  reviewId,
  hasLiked,
  reviewAuthorId,
  nickName,
  productName,
  productType,
  brandName,
}: Props) => {
  const showLoginPopUp = useModalManager('LoginPopUp');
  const { data: currentUser } = useQuery(userQueryOptions.getCurrentUser());
  const queryClient = useQueryClient();

  const { isPending, mutate: likeMutation } = useMutation({
    mutationFn: postReviewLike,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getProductReviewsLoggedIn', productId],
      });

      (async function trackLikeEvent() {
        trackEvent(
          hasLiked ? eventNames.likeReview_undo : eventNames.likeReview,
          currentUser,
          {
            productName: productName,
            productType: productType,
            brandName: brandName,
            authorId: reviewAuthorId,
            authorNickname: nickName,
            platformType: getPlatformType(),
          }
        );
      })();
    },
  });

  const handleLikeClick = async () => {
    if (isPending) {
      return;
    }
    try {
      if (!currentUser || currentUser.isAnonymous) {
        showLoginPopUp({ source: 'likeBtn' });
        return;
      }

      likeMutation(reviewId);
    } catch (error) {
      console.error('Failed to post review like:', error);
    }
  };

  return (
    <S.LikeContainer>
      <S.LikeContent>
        <S.LikeImage onClick={handleLikeClick}>
          {hasLiked ? <LikeImageFilled /> : <LikeImageUnfilled />}
        </S.LikeImage>
        <S.LikeTextContainer>
          <S.LikeText>{likeCount}</S.LikeText>
        </S.LikeTextContainer>
      </S.LikeContent>
    </S.LikeContainer>
  );
};

export default ReviewLike;
