import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { approvePaypal } from '../../../utils/request';

const PaypalComplete = () => {
  const { t } = useTranslation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  useEffect(() => {
    if (token) {
      approvePaypal(token).then(() => {
        window.opener?.postMessage('Paypal payment is completed!');
        window.close();
      });
    }
  }, [token]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100wh',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          fontFamily: 'Pretendard-Medium',
          fontSize: '16px',
          lineHeight: '22px',
        }}
      >
        {t('store.purpleChargingModal.tossPayComplete')}
      </span>
    </div>
  );
};

export default PaypalComplete;
