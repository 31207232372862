import { ProductCategory } from 'src/types/product';
import { API_URL } from 'src/utils/configs';
import apiClient from './apiClient';
import { BaseResponse } from './types/common.types';
import { PurchasedProducts } from './types/user.types';

export const getPurchasedProducts = async (filter: ProductCategory) => {
  const url = `${API_URL}/api/products/purchased-products?filter=${filter}`;

  return apiClient.get<BaseResponse<PurchasedProducts>>(url);
};
