import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import store_images from '../../../assets/images/store/images';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import useReactNativeEvents from '../../../hooks/useReactNativeEvents';
import { eventNames, goStorageSources } from '../../../utils/mixpanel';
import { getTemplateOrFolderId } from '../../../utils/request';
import * as S from './PaymentComplete.styles';
import { useEffect } from 'react';

function PaymentComplete({ title, isOpen, onClose, product }) {
  const { t } = useTranslation();
  const { postMessageToRN } = useReactNativeEvents();
  const { data: currentUser } = useCurrentUserQuery();
  const isApp = localStorage.getItem('platform');
  const showUseStorageInAppPopUp = useModalManager('UseStorageInAppPopUp');

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  useEffect(() => {
    if (isOpen) {
      postMessageToRN('fetchData', {
       dataType: 'foldersList',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const goToStorage = async () => {
    if (currentUser) {
      trackEvent(eventNames.goToStorageClick, currentUser, {
        source: goStorageSources.purchaseProduct_complete,
        platformType,
      });
    }

    if (isApp) {
      const response = await getTemplateOrFolderId(product._id);
      postMessageToRN('navigateTo', {
        screen: 'StorageScreen',
        hideBottomTabs: false,
        tab: product.productType,
        id: response?.data.data?.id,
      });
    } else {
      showUseStorageInAppPopUp({ source: 'purchaseProduct_complete' });
    }
    onClose();
  };

  const continueShopping = () => {
    onClose();
  };

  return (
    <>
      {isOpen && <S.Overlay onClick={onClose} />}
      <S.BottomSheetComplete $isOpen={isOpen}>
        <S.PaymentCompleteBottomSheetContent>
          <S.PurchaseDetailContainer>
            <S.PurchaseComplete>
              <S.CompleteIconResize src={store_images.complete} alt='complete-icon' />

              <S.CompleteText>{t('store.paymentModal.complete')}</S.CompleteText>
            </S.PurchaseComplete>

            <S.CompleteDetailText>{title}</S.CompleteDetailText>

            <S.CompleteDetailText>
              {t('store.paymentModal.purchaseCompleted')}
            </S.CompleteDetailText>
          </S.PurchaseDetailContainer>

          <S.PaymentCompleteButtonContainer>
            <S.GoStorageButton onClick={goToStorage}>
              <S.GoStorageText>{t('store.paymentModal.goStorage')}</S.GoStorageText>
            </S.GoStorageButton>

            <S.GoShoppingButton onClick={continueShopping}>
              <S.GoShoppingText>{t('store.paymentModal.goShop')}</S.GoShoppingText>
            </S.GoShoppingButton>
          </S.PaymentCompleteButtonContainer>
        </S.PaymentCompleteBottomSheetContent>
      </S.BottomSheetComplete>
    </>
  );
}

export default PaymentComplete;
