import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurplePointsData } from 'src/types/store/commonPropsTypes';
import { getPlatform } from 'src/utils/platformUtils';
import checkboxFilled from '../../../assets/images/store/Check-box-filled.png';
import checkboxRed from '../../../assets/images/store/Check-box-red.png';
import checkOff from '../../../assets/images/store/Check-off.svg';
import purpleIcon from '../../../assets/images/store/purple-icon.png';
import purpleGrayIcon from '../../../assets/images/store/purple_gray_icon.png';
import { UserContext } from '../../../context/userContext';
import { showToast } from '../../../utils/_common/showToast';
import { thousandsFormate } from '../../../utils/amount';
import { getPurplePointsList } from '../../../utils/request';
import * as G from '../StoreComponent.styles';
import PointCardUnit from './PointCardUnit';
import * as S from './PurpleChargingModal.styles';
import { usePurpleCharge } from './hooks/usePurpleCharge';
const checkboxImgs = [checkOff, checkboxFilled, checkboxRed];

type Props = {
  data: any;
  purpleCount: string;
  selectedUnitIndex: number;
  setSelectedUnitIndex: React.Dispatch<React.SetStateAction<number>>;
  showCompleteModal: () => void;
  onPointsChange?: (selectedPoints: PurplePointsData) => void;
};

function PurpleChargingModal({
  data,
  purpleCount,
  selectedUnitIndex,
  setSelectedUnitIndex,
  showCompleteModal,
  onPointsChange,
}: Props) {
  const userId = localStorage.getItem('userId') ?? '';
  const accessToken = localStorage.getItem('accessToken') ?? '';
  const { t } = useTranslation();
  const platform = getPlatform();
  const { refreshUserBalance, currentUser, getLoginUser } = useContext(UserContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [purplePoints, setPurplePoints] = useState<
    {
      points: number;
      bonusPoints: number;
      productId: string;
      price: number;
      priceInWeb: number;
    }[]
  >([]);
  const [selectedMethod, setSelectedMethod] = useState<'TossPay' | 'KakaoPay' | 'Paypal'>(
    'TossPay'
  );

  const totalChargedPoints = thousandsFormate(
    currentUser?.purchaseCount === 0
      ? Number(purplePoints[selectedUnitIndex]?.points) * 2
      : Number(purplePoints[selectedUnitIndex]?.points) *
          Number(1 + purplePoints[selectedUnitIndex]?.bonusPoints / 100)
  );

  // to scroll
  const paymentUnderRef = useRef<HTMLDivElement>(null);

  const [showPurchaseInfo, setShowPurchaseInfo] = useState(false);

  const isWeb = platform !== 'ios' && platform !== 'android';

  //scroll down after selecting unit
  useEffect(() => {
    if (paymentUnderRef.current) {
      paymentUnderRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    if (selectedUnitIndex !== -1) {
      const timeoutId = setTimeout(() => {
        setShowPurchaseInfo(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedUnitIndex, showPurchaseInfo]);

  useEffect(() => {
    if (selectedUnitIndex !== -1 && purplePoints.length > 0 && onPointsChange) {
      onPointsChange(purplePoints[selectedUnitIndex]);
    }
  }, [selectedUnitIndex, purplePoints, onPointsChange]);

  const fetchPoints = async () => {
    const response = await getPurplePointsList();
    if (response?.data?.status) {
      const purplePoints = response?.data?.data;
      const initialIndex = purplePoints.findIndex((item) => data?.points === item.points);
      setPurplePoints(purplePoints ?? []);
      setSelectedUnitIndex(initialIndex);
    }
  };

  useEffect(() => {
    refreshUserBalance();
    fetchPoints();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckbox = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 1 ? 0 : 1));
  };
  const onPaymentSuccess = async () => {
    await refreshUserBalance();
    await getLoginUser();
    setCurrentImageIndex(0);
    setIsBottomSheetOpen(true);
    showCompleteModal();
  };
  const { isLoading, handleCharge } = usePurpleCharge(
    //To purchase with the changed unit value within the modal
    purplePoints[selectedUnitIndex],
    selectedMethod,
    currentUser,
    currentImageIndex,
    setCurrentImageIndex,
    onPaymentSuccess
  );

  const handlePaymentButtonClick = () => {
    //if user doesn't select purple unit
    if (selectedUnitIndex === -1) {
      showToast({
        toastMessage: t('store.purpleChargingModal.toast1'),
        toastType: 'success',
      });
    }
    //if user is using web & doesn't select payment method
    else if (isWeb && !selectedMethod) {
      showToast({
        toastMessage: t('store.purpleChargingModal.toast2'),
        toastType: 'success',
      });
    }
    //if user doesn't check terms of use
    else if (currentImageIndex === 2) {
      showToast({
        toastMessage: t('store.purpleChargingModal.toast3'),
        toastType: 'success',
      });
    }
  };

  const handleKakaoPayBtnClick = () => {
    setSelectedMethod('KakaoPay');
  };

  const handlePaypalBtnClick = () => {
    setSelectedMethod('Paypal');
  };

  const handleTossPayBtnClick = () => {
    setSelectedMethod('TossPay');
  };

  return (
    <S.BottomSheetCharging>
      <S.BottomSheetHeader>
        <S.HeaderHandle />
      </S.BottomSheetHeader>
      <S.PurpleChargingContent>
        {!isBottomSheetOpen && (
          <>
            <S.PurpleChargingContentsContainer>
              <S.PurpleChargingPurplePrice>
                {t('store.purpleStation.charge')}&nbsp;
              </S.PurpleChargingPurplePrice>
              <S.CurrentBalance>
                <S.MyPurpleBeforeCharging>
                  {t('store.purpleStation.holdingPurpleBegin')}&nbsp;
                </S.MyPurpleBeforeCharging>
                <S.PurpleChargingPurpleIconContainer>
                  <S.PurpleChargingPurpleIconResize
                    src={purpleGrayIcon}
                    alt='purple gray icon'
                  />
                  <S.PurpleChargingPurpleIconText>P</S.PurpleChargingPurpleIconText>
                </S.PurpleChargingPurpleIconContainer>
                <S.MyPurpleBeforeCharging>{purpleCount}</S.MyPurpleBeforeCharging>
              </S.CurrentBalance>
            </S.PurpleChargingContentsContainer>

            <S.ModalInnerContainer>
              {/* Select unit part */}
              <S.PointCardUnitContainer>
                {purplePoints.map((item, index) => (
                  <PointCardUnit
                    item={item}
                    index={index}
                    selectedUnitIndex={selectedUnitIndex}
                    setSelectedUnitIndex={setSelectedUnitIndex}
                  />
                ))}
              </S.PointCardUnitContainer>
              {showPurchaseInfo === true && (
                <>
                  <S.DivideBar />
                  {/* Charging Info part */}
                  <S.ChargingInfoContainer>
                    <S.PartTitle>
                      {t('store.purpleChargingModal.chargingInfoTitle')}
                    </S.PartTitle>
                    <S.PurpleChargingChargeBar>
                      <S.PurpleChargingText>
                        {t('store.purpleChargingModal.totalChargingPurple')}
                      </S.PurpleChargingText>
                      <S.PurpleChargingPointContainer>
                        <S.PurpleChargingPurpleIconContainer>
                          <S.PurpleChargingPurpleIconResize
                            src={purpleIcon}
                            alt='purple icon'
                          />
                          <S.PurpleChargingPurpleIconText>
                            P
                          </S.PurpleChargingPurpleIconText>
                        </S.PurpleChargingPurpleIconContainer>
                        <S.PurpleChargingPoint>
                          {totalChargedPoints}
                          {t('store.purpleChargingModal.P')}
                        </S.PurpleChargingPoint>
                      </S.PurpleChargingPointContainer>
                    </S.PurpleChargingChargeBar>
                  </S.ChargingInfoContainer>
                  <S.PurpleChargingPriceBar>
                    <S.PurpleChargingPriceText>
                      {t('store.purpleChargingModal.amount')}
                    </S.PurpleChargingPriceText>

                    <S.PurpleChargingPrice>
                      {platform === 'ios' || platform === 'android'
                        ? thousandsFormate(purplePoints[selectedUnitIndex]?.price)
                        : thousandsFormate(purplePoints[selectedUnitIndex]?.priceInWeb)}
                      {t('store.purpleChargingModal.krw')}
                    </S.PurpleChargingPrice>
                  </S.PurpleChargingPriceBar>
                  <S.DivideBar />
                  {/* choose payment method part */}
                  {isWeb && (
                    <>
                      <S.PaymentMethodContainer>
                        <S.PaymentMethodTitleContainer>
                          <S.PartTitle>
                            {t('store.purpleChargingModal.paymentMethod')}
                          </S.PartTitle>
                        </S.PaymentMethodTitleContainer>
                        <S.PaymentMethodButtonContainer>
                          <S.PaymentMethodBtn
                            $selected={selectedMethod === 'TossPay'}
                            onClick={handleTossPayBtnClick}
                          >
                            <S.PaymentMethodBtnText
                              $selected={selectedMethod === 'TossPay'}
                            >
                              {t('store.purpleChargingModal.tossPay')}
                            </S.PaymentMethodBtnText>
                          </S.PaymentMethodBtn>
                          <S.PaymentMethodBtn
                            $selected={selectedMethod === 'KakaoPay'}
                            onClick={handleKakaoPayBtnClick}
                          >
                            <S.PaymentMethodBtnText
                              $selected={selectedMethod === 'KakaoPay'}
                            >
                              {t('store.purpleChargingModal.kakaoPay')}
                            </S.PaymentMethodBtnText>
                          </S.PaymentMethodBtn>
                          <S.PaymentMethodBtn
                            $selected={selectedMethod === 'Paypal'}
                            onClick={handlePaypalBtnClick}
                          >
                            <S.PaymentMethodBtnText
                              $selected={selectedMethod === 'Paypal'}
                            >
                              {t('store.purpleChargingModal.Paypal')}
                            </S.PaymentMethodBtnText>
                          </S.PaymentMethodBtn>
                        </S.PaymentMethodButtonContainer>
                      </S.PaymentMethodContainer>
                      <S.DivideBar />
                    </>
                  )}
                  <G.WarningCheckbox ref={paymentUnderRef}>
                    <G.WarningCheckboxRow onClick={handleCheckbox}>
                      <G.WarningCheckboxIcon
                        src={checkboxImgs[currentImageIndex]}
                        alt='checkbox'
                      />
                      <G.WarningCheckboxText>
                        {t('store.purpleChargingModal.check')}
                      </G.WarningCheckboxText>
                    </G.WarningCheckboxRow>
                    {currentImageIndex === 2 ? (
                      <G.WarningCheckboxCaution>
                        {t('store.purpleChargingModal.checkWarn')}
                      </G.WarningCheckboxCaution>
                    ) : null}
                  </G.WarningCheckbox>
                  <S.PurpleChargingWarningContainer>
                    <S.PurpleChargingWarningText>
                      <G.Ul>
                        <li>
                          <G.WarningTextList>
                            {t('store.purpleChargingModal.termsOfUse1')}
                          </G.WarningTextList>
                        </li>

                        <li>
                          <G.WarningTextList>
                            {t('store.purpleChargingModal.termsOfUse2')}
                          </G.WarningTextList>
                        </li>
                        <li>
                          <G.WarningTextList>
                            {t('store.purpleChargingModal.termsOfUse3')}
                          </G.WarningTextList>
                        </li>
                        <li>
                          <G.WarningTextList>
                            {t('store.purpleChargingModal.termsOfUse4')}
                          </G.WarningTextList>
                        </li>
                      </G.Ul>
                    </S.PurpleChargingWarningText>
                  </S.PurpleChargingWarningContainer>
                </>
              )}
            </S.ModalInnerContainer>
            {userId && accessToken && (
              <S.PurpleChargingButtonContainer>
                <S.PurpleChargingButton
                  disabled={isLoading}
                  onClick={() => {
                    handlePaymentButtonClick();
                    handleCharge();
                  }}
                >
                  {isLoading ? (
                    <G.LoadingContainer>
                      <S.Progress size={20} sx={{ color: 'white' }} />
                    </G.LoadingContainer>
                  ) : (
                    <S.PurpleChargingButtonText>
                      {t('store.purpleChargingModal.charge')}
                    </S.PurpleChargingButtonText>
                  )}
                </S.PurpleChargingButton>
              </S.PurpleChargingButtonContainer>
            )}
          </>
        )}
      </S.PurpleChargingContent>
    </S.BottomSheetCharging>
  );
}

export default PurpleChargingModal;
