import { useTranslation } from 'react-i18next';
import * as S from './index.styles';
import { me } from 'src/utils/request';
import useNavigate from '../../hooks/useNavigation';

const MaintenancePage = () => {
  const { t } = useTranslation();
  const { navigateTo, navigateToAppScreen } = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const fromScheduleTime = urlParams.get('fromScheduleTime') ?? '';
  const toScheduleTime = urlParams.get('toScheduleTime') ?? '';

  const goToStore = async () => {
    const response = await me();
    if (response?.data?.status) {
      const platform = localStorage.getItem('platform') ?? new URLSearchParams(window.location.search).get('platform');
      if (platform === 'ios' || platform === 'android') {
        navigateToAppScreen({ screen: 'StorageScreen', hideBottomTabs: false });
      } else {
        navigateTo('/store');
      }
    }
  }

  return (
    <S.Container>
      <S.Image />
      <S.Title>{t('maintenance.title')}</S.Title>
      <S.ScheduleText>{t('maintenance.scheduleText', { fromScheduleTime, toScheduleTime })}</S.ScheduleText>
      <S.Text>{t('maintenance.text')}</S.Text>
      <S.Button onClick={goToStore}>{t('errorPage.goBack')}</S.Button>

    </S.Container>
  );
};

export default MaintenancePage;
