import { useTranslation } from 'react-i18next';
import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { images } from '../../../../../assets/images/search/images';
import store_images from '../../../../../assets/images/store/images';
import { trackEvent } from '../../../../../hooks/store/useMixpanel';
import useNavigate from '../../../../../hooks/useNavigation';
import { ProductDataProps } from '../../../../../types/search/commonPropsTypes';
import { highlightedText } from '../../../../../utils/highlightedText';
import { eventNames } from '../../../../../utils/mixpanel';
import * as G from '../../ProductCommon.styles';
import * as S from './FragmentStickerData.styles';

const FragmentStickerData = ({ text, data }: ProductDataProps) => {
  const { data: currentUser } = useCurrentUserQuery();

  const { navigateTo, navigateToStorageOrInternalLink, navigateToAppScreen } =
    useNavigate();
  const showUseStorageInAppPopUp = useModalManager('UseStorageInAppPopUp');
  const userId = localStorage.getItem('userId') ?? '';
  const { t, i18n } = useTranslation();
  const platform = localStorage.getItem('platform') ?? '';

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isWeb = platformType === 'web';
  const isApp = platformType === 'app';
  const isUser = currentUser ? true : false;

  return (
    <G.MainDiv>
      <G.ProductLayout>
        {data.map((item: any, index: number) => {
          const productId = item?.productId;
          const title = item.product
            ? i18n.language === 'en'
              ? item.product?.engName || item.product?.korName
              : item.product?.korName || item.product?.engName
            : i18n.language === 'en'
            ? item.folder?.folderNameEnglish || item.folder?.folderName
            : item.folder?.folderName || item.folder?.folderNameEnglish;

          const creatorName = item.brand
            ? i18n.language === 'en'
              ? item.brand?.nameEN
              : item.brand?.name
            : item.creator;
          const isPurchased = item?.isPurchased === true;
          const isOwnProduct =
            item?.userId === userId || item?.brand?.connectedHaplAccount === userId;
          const isMyStorage = isOwnProduct || isPurchased;

          return (
            <G.ProductContainer
              key={index}
              onClick={() => {
                if (currentUser)
                  trackEvent(eventNames.searchResultItem, currentUser, {
                    categoryName: 'fragment_sticker',
                    productName: title,
                    brandName: creatorName,
                    isMyStorage,
                    sequence: index + 1,
                    platformType,
                    isUser,
                  });

                if (isOwnProduct) {
                  if (isWeb) {
                    showUseStorageInAppPopUp();
                  } else {
                    navigateToStorageOrInternalLink({ item, platform });
                  }

                  return;
                }

                if (isPurchased) {
                  if (isWeb) {
                    navigateTo(`${PATH.TEMPLATE_DETAIL}/${productId}`);
                  } else {
                    navigateToStorageOrInternalLink({ item, platform });
                  }

                  return;
                }

                if (isApp) {
                  const payload = {
                    productId,
                    comingFrom: 'SearchScreen',
                    productType: 'sticker/detail',
                    screen: 'StoreScreen',
                  };
                  navigateToAppScreen(payload);
                  return;
                }

                navigateTo(`${PATH.STICKER_DETAIL}/${productId}`);
              }}
            >
              {isMyStorage && (
                <G.FromStorageContainer>
                  <G.FromStorageText>{t('search.resultTab.storage')}</G.FromStorageText>
                </G.FromStorageContainer>
              )}

              <S.StickerThumbnail>
                <S.ThumbnailResize
                  src={item?.signUrl || store_images.defaultProfileSquare}
                  alt='thumbnail'
                />
              </S.StickerThumbnail>

              <G.FragTextContainer>
                <G.ProductTitleContainer>
                  <G.ProductTitleContainer>
                    <G.ProductTitle>{highlightedText(title, text)}</G.ProductTitle>
                  </G.ProductTitleContainer>
                  <img src={images.arrowRight} alt='arrow_right' width={8} height={22} />
                </G.ProductTitleContainer>

                <G.CreatorAreaContainer>
                  <G.CreatorImageContainer>
                    <G.CreatorImageResize
                      src={item?.creatorImage || store_images.defaultProfile}
                      alt={item?.creator}
                    />
                  </G.CreatorImageContainer>
                  <G.CreatorContainer>
                    <G.CreatorName>{creatorName}</G.CreatorName>
                  </G.CreatorContainer>
                </G.CreatorAreaContainer>
              </G.FragTextContainer>
            </G.ProductContainer>
          );
        })}
      </G.ProductLayout>
    </G.MainDiv>
  );
};

export default FragmentStickerData;
