import colors from 'src/utils/colors';
import styled from 'styled-components';

export const LikeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  padding: 0 24px;
`;

export const LikeContent = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const LikeImage = styled.div`
  width: 24px;
  height: 24px;
`;

export const LikeTextContainer = styled.div`
  display: flex;
  min-width: 15px;
`;

export const LikeText = styled.div`
  color: ${colors.$gray500};
  ${({ theme }) => theme.font.B2_REGULAR};
`;
