export const PATH = {
  DEFAULT: '/',
  STORE: '/store',
  STICKER_DETAIL: '/store/sticker/detail',
  TEMPLATE_DETAIL: '/store/template/detail',
  BRAND_DETAIL: '/store/brand/detail',
  SEARCH: '/search',
  COMING_SOON: '/coming-soon',
  MYPAGE: '/mypage',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  PURCHASE_HISTORY: '/purchase-history',
  CREATE_REVIEW: '/review/create',
  EDIT_REVIEW: '/review/edit',
  ACCESS_DENIED: '/access-denied',
  MAINTENANCE_PAGE: '/maintenance',
} as const;
