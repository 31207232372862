import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import useReactNativeEvents from 'src/hooks/useReactNativeEvents';
import { PurplePointsData } from 'src/types/store/commonPropsTypes';
import { BASE_URL } from 'src/utils/configs';
import { eventNames, productProps } from 'src/utils/mixpanel';
import { getPlatform } from 'src/utils/platformUtils';
import checkboxFilled from '../../../assets/images/store/Check-box-filled.png';
import checkboxRed from '../../../assets/images/store/Check-box-red.png';
import checkOff from '../../../assets/images/store/Check-off.svg';
import { UserContext } from '../../../context/userContext';
import { productLabel } from '../../../helpers/products';
import { thousandsFormate } from '../../../utils/amount';
import DonateAuthCard from '../ExhibitionPageComponents/DonateAuthCard/DonateAuthCard';
import PurpleChargingModal from '../Purple/PurpleChargingModal';
import * as G from '../StoreComponent.styles';
import PaymentCompleteModal from './PaymentComplete';
import * as S from './PaymentModal.styles';
import PaymentWarningList from './PaymentWarningList';
import { useProductPurchase } from './hooks/useProductPurchase';

const checkboxImgs = [checkOff, checkboxFilled, checkboxRed];

type Props = {
  product: any;
  isOpen: boolean;
  onClose: () => void;
  onPurchase: () => void;
};

const PaymentModal = ({ product, isOpen, onClose, onPurchase }: Props) => {
  const { postMessageToRN } = useReactNativeEvents();
  const { t, i18n } = useTranslation();
  const { currentBalance, refreshUserBalance } = useContext(UserContext);
  const { data: currentUser } = useCurrentUserQuery();
  const title = productLabel(product, i18n);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isDonateCertificateModalOpen, setIsDonateCertificateModalOpen] = useState(false);
  const [isChargingModalOpen, setIsChargingModalOpen] = useState(false);
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(-1);

  const [paidPurple, setPaidPurple] = useState<PurplePointsData | null>(null);

  const platform = getPlatform();
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const handlePointsChange = (selectedData: PurplePointsData) => {
    setPaidPurple(selectedData);
  };

  const handleCheckbox = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 1 ? 0 : 1));
  };

  const handleCloseBottomSheet = () => {
    setIsBottomSheetOpen(false);
  };

  const handleCloseDonateCertificateModal = () => {
    setIsDonateCertificateModalOpen(false);
    setIsBottomSheetOpen(true);
  };

  const { affordAble, handleCharge, isLoading } = useProductPurchase(
    currentImageIndex,
    setCurrentImageIndex,
    product,
    onPurchase,
    onClose,
    setIsBottomSheetOpen,
    setIsDonateCertificateModalOpen
  );

  const toggleModal = () => {
    setIsChargingModalOpen((isChargingModalOpen) => !isChargingModalOpen);
  };

  const closeChargingModal = () => {
    setIsChargingModalOpen(false);
  };

  const handleChargeAndOpenModal = () => {
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    if (isLoading) return;
    if (!affordAble) {
      toggleModal();
      trackEvent(eventNames.clickChargePoint, currentUser, {
        ...productProps(product),
        platformType,
      });
    } else {
      handleCharge();
      postMessageToRN('clickPurchaseProduct', null);
    }
  };

  useEffect(() => {
    const successPaymentFunction = (event) => {
      if (
        event.origin === BASE_URL &&
        (event.data === 'Toss payment is completed!' ||
          event.data === 'Kakao payment is completed!' ||
          event.data === 'Paypal payment is completed!')
      ) {
        setIsChargingModalOpen(false);
        refreshUserBalance();
        if (paidPurple)
          trackEvent(eventNames.pointPaymentComplete, currentUser, {
            point: paidPurple.points,
            bonusPoint: paidPurple.bonusPoints,
            price: platformType === 'app' ? paidPurple.price : paidPurple.priceInWeb,
            currency: 'KRW',
            paymentMethod:
              event.data === 'Toss payment is completed!'
                ? 'TossPay'
                : event.data === 'Kakao payment is completed!'
                ? 'KakaoPay'
                : 'Paypal',
            platformType,
          });
      }
    };

    window.addEventListener('message', successPaymentFunction);
    return () => {
      window.removeEventListener('message', successPaymentFunction);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isScrollDisabled = isBottomSheetOpen || isChargingModalOpen;

  useEffect(() => {
    if (isScrollDisabled) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isScrollDisabled]);

  return (
    <S.BottomSheet $isMounted={isOpen}>
      <S.BottomSheetContent>
        <S.BottomSheetTitleContainer>
          <S.BottomSheetTitle>{title}</S.BottomSheetTitle>
        </S.BottomSheetTitleContainer>

        <S.BottomSheetContentsContainer>
          <S.PurchaseWarning>
            <G.WarningCheckbox>
              <G.WarningCheckboxRow onClick={handleCheckbox}>
                <G.WarningCheckboxIcon
                  src={checkboxImgs[currentImageIndex]}
                  alt='checkbox'
                />
                <G.WarningCheckboxText>
                  {t('store.paymentModal.check')}
                </G.WarningCheckboxText>
              </G.WarningCheckboxRow>

              {currentImageIndex === 2 ? (
                <G.WarningCheckboxCaution>
                  {t('store.paymentModal.checkWarn')}
                </G.WarningCheckboxCaution>
              ) : null}
            </G.WarningCheckbox>

            <div>
              <S.WarningTitle>{t('store.paymentModal.noteTitle')}</S.WarningTitle>

              <S.WarningText>
                <PaymentWarningList />
              </S.WarningText>
            </div>
          </S.PurchaseWarning>

          <S.AboutPurples>
            <S.PurplePointBox>
              <S.PurplesText>
                <S.HoldingPurplesText>
                  {t('store.paymentModal.myPurple')}
                </S.HoldingPurplesText>

                <S.HoldingPurplesAmount $isWarning={!affordAble}>
                  {!affordAble && (
                    <S.HoldingPurplesAmountWarning>
                      {t('store.paymentModal.notEnoughPurple')}
                    </S.HoldingPurplesAmountWarning>
                  )}
                  <S.HoldingPurplesAmountWarningP>
                    {thousandsFormate(currentBalance)} P
                  </S.HoldingPurplesAmountWarningP>
                </S.HoldingPurplesAmount>
              </S.PurplesText>

              <S.PurplesText>
                <S.PurchasedPurplesText>
                  {t('store.paymentModal.paymentPurple')}
                </S.PurchasedPurplesText>

                <S.PurchasedPurplesAmount>
                  {thousandsFormate(product?.salePrice)} P
                </S.PurchasedPurplesAmount>
              </S.PurplesText>
            </S.PurplePointBox>

            <S.PurchasingPurplesButton onClick={handleChargeAndOpenModal}>
              {isLoading ? (
                <G.LoadingContainer>
                  <G.Progress size={20} />
                </G.LoadingContainer>
              ) : (
                <S.PurchasingPurplesText>
                  {t(
                    affordAble
                      ? 'store.paymentModal.purchase'
                      : 'store.paymentModal.goToPurpleStore'
                  )}
                </S.PurchasingPurplesText>
              )}
            </S.PurchasingPurplesButton>

            {isDonateCertificateModalOpen && (
              <DonateAuthCard onBackDropClick={handleCloseDonateCertificateModal} />
            )}

            <PaymentCompleteModal
              title={title}
              isOpen={isBottomSheetOpen}
              onClose={handleCloseBottomSheet}
              product={product}
            />
          </S.AboutPurples>
        </S.BottomSheetContentsContainer>
      </S.BottomSheetContent>
      {isChargingModalOpen && (
        <>
          <S.PurpleChargingInPaymentOverlay onClick={toggleModal} />
          <PurpleChargingModal
            data={null}
            purpleCount={thousandsFormate(currentBalance)}
            selectedUnitIndex={selectedUnitIndex}
            setSelectedUnitIndex={setSelectedUnitIndex}
            showCompleteModal={closeChargingModal}
            onPointsChange={handlePointsChange}
          />
        </>
      )}
    </S.BottomSheet>
  );
};

export default PaymentModal;
